import React, {Component} from 'react'
import logo from '../images/minniesLogo.PNG';



class TakePicture extends Component {
    constructor(props) {
      super(props);

      this.state = {}; 
      sessionStorage.setItem('blobImage', "");
      
    }

    

     takePicture() {
    
      var canvas = document.getElementById('canvas');
      var context = canvas.getContext('2d');
      var video = document.getElementById('video');
      

      context.drawImage(video, 0, 0, 340, 280);
      sessionStorage.setItem('blobImage', canvas.toDataURL());
      
     }
  
    componentDidMount() {

   
      alert("Note: Please click Accept if/when it asked you to give the site permission to use your device camera.  "
      + "This is needed so that you can take submit a picture of your proof of residence.")
      var video = document.getElementById('video');
      
     
      if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
       // show front camera  navigator.mediaDevices.getUserMedia({ video: true,facingMode: "environment" }).then(function(stream) {
            navigator.mediaDevices.getUserMedia({video : {facingMode : {exact : "environment"}}}).then(function(stream) {  //show rear camera
              video.srcObject = stream;
              video.play();
          });
      }
      
     
    }

 
  
    componentWillUnmount() {
    }
    
     isValidated() {
      let valid = true;
      return valid;
    }

  
    render() {
      return (
        <div>
          <div className="row">
          <div className="col-sm-3">
          <img src={logo} alt="Logo" />
          </div>
          <div className="col-sm-9">
        <h4>Optional: Please take a picture to submit proof of residence or just click the 'NEXT' button below.</h4>
        <h4>ACCEPTABLE FORM OF VALIDATION:</h4>
        <h5>Utility Bill, Lease, Government Documents (W2 Forms, Social
            Security letter, voter’s registration card, Jury Duty notice, HUD
            letter, SNAP benefits form, car registration, IRS statement,
            property tax statement.</h5>
            <h3 style={{color: "red"}}>MUST BE DATED WITHIN 60 DAYS.</h3>
        </div>
        </div>
        <video id="video" width="340" height="280" autoPlay playsInline></video>
        <br />
        <button onClick={()=> this.takePicture()} id="snap">Snap Photo</button>
        <br /> <br />
        <canvas id="canvas" width="340" height="280"></canvas>
      
      </div>
      )
    }
  }

  export default TakePicture