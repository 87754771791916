import React, {Component} from 'react'
import logo from '../images/minniesLogo.PNG';


class ThankYou extends React.Component {
  constructor(props) {
    super(props);
      this.state = { didErrorOccur : false }; 
  }

  componentDidMount() { 
    var urlParams = new URLSearchParams(window.location.search);
    var rName = urlParams.get('go');
    
    if(rName != null && rName == "yes") {
      this.state.didErrorOccur = true;   
    }
  }

  componentWillUnmount() {
    sessionStorage.clear();

}
  goBackHome(){
    sessionStorage.clear();
    this.props.history.push('/');
  }

  
  render() {
    return (
      <div>

<div className="row">
    <div className="col-sm-3">
           <img src={logo} alt="Logo" />
           </div>
           <div className="col-sm-8">
             <br></br>
           <h2>Your Application was successfully submitted.</h2>
           <h2 hidden={this.state.didErrorOccur == true ? true : false}>-----------------------------------</h2>
           <h2>First Name: {sessionStorage.getItem('fName')}</h2>
           <h2>Last Name: {sessionStorage.getItem('lName')}</h2>
           <h2>Birthday: {sessionStorage.getItem('birthday_month') + "/" + sessionStorage.getItem('birthday_day') + "/" + sessionStorage.getItem('birthday_year')}</h2>
           <br></br>
           <h6>Thank you for allowing Minnie's Food Pantry to serve you.</h6>
           <h6>Minnie's Food Pantry is open Wednesday-Saturday from 8:30 AM - 11:30 AM. </h6>
           <h6>Minnie's Food Pantry is CLOSED on the 5th Saturday of the month.</h6>
           <h6>During your visit, you will receive: non-perishable items, fresh meats, fresh produce, dairy products and bakery goods (based on availability).</h6>
           <h6>Please be sure to bring your photo ID during EVERY visit. </h6>
           <h6>If you have any questions regarding your service, please feel free to reach out at any time (972)596-0253.</h6>

           <br></br>
           <br></br>

           <button id="submitBtn" className="btn btn-prev btn-primary btn-lg" onClick={()=> this.goBackHome()}>Go Back To Home Page</button>
           </div>
           </div>

      </div>
    );
  }
};

export default ThankYou;