
import React, {Component} from 'react'
import logo from '../images/minniesLogo.PNG';
import {
  withRouter
} from 'react-router-dom'


class SummarySubmit extends Component {
    constructor(props) {
      super(props);
      //this.isValidated = this.isValidated.bind(this);
      try {
        this.state = {myPic : window.btoa(sessionStorage.getItem('blobImage').replace("data:image/png;base64,","")) }; 
      } catch (error) {
        this.state = {myPic : "" }; 
      }
     
    }

 

    submitGuest()  {
      
      document.getElementById('submitBtn').disabled = true;
      
      var obj = {
        FirstName : sessionStorage.getItem('fName'),
        MiddleName : sessionStorage.getItem('mName'),
        LastName : sessionStorage.getItem('lName'),
        Birthday : sessionStorage.getItem('birthday_month') + "/" + sessionStorage.getItem('birthday_day') + "/" + sessionStorage.getItem('birthday_year'),
        Gender : sessionStorage.getItem('gender'),
        ReferredByName : sessionStorage.getItem('referredby'),
        Ethnicity : sessionStorage.getItem('ethnicity'),
        Email : sessionStorage.getItem('email'),
        CellPhone : sessionStorage.getItem('phone'),
        StreetAddress : sessionStorage.getItem('address'),
        City : sessionStorage.getItem('city'),
        State : sessionStorage.getItem('state'),
        Zip : sessionStorage.getItem('zip'),
        MilitaryStatus : sessionStorage.getItem('military'),
        EmploymentStatus : sessionStorage.getItem('employee'),
        MonthlyIncome : sessionStorage.getItem('income'),
        FoodStampsYN : sessionStorage.getItem('snapyn'),
        ReduceLunchYN : sessionStorage.getItem('reduceyn'),
        LearnSnapYN : sessionStorage.getItem('learnyn'),
        HouseHold1Name : sessionStorage.getItem('household1name'),
        HouseHold1Birthday : sessionStorage.getItem('household1birthday'),
        HouseHold1Gender : sessionStorage.getItem('household1gender'),
        HouseHold2Name : sessionStorage.getItem('household2name'),
        HouseHold2Birthday : sessionStorage.getItem('household2birthday'),
        HouseHold2Gender : sessionStorage.getItem('household2gender'),
        HouseHold3Name : sessionStorage.getItem('household3name'),
        HouseHold3Birthday : sessionStorage.getItem('household3birthday'),
        HouseHold3Gender : sessionStorage.getItem('household3gender'),
        ProofResidenceBlob : window.btoa(sessionStorage.getItem('blobImage').replace("data:image/png;base64,",""))//.toString().replace("data:image/png;base64,",""))
        } 
      //  fetch('https://localhost:44372/api/values/submitguestnew', {
      fetch('https://www.invisionthat.com/minnies/api/values/submitguestnew', {
    method: 'post',

       headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
        },
    body: JSON.stringify(obj)
  }).then(res => res.json()).then(response => {
  
    if(response.didSucceed) {
       this.props.history.push('/thankyou');
      
    }
    else {

      this.props.history.push('/thankyou?go=yes');
     // alert('Submission Unsuccessful.  Close and reopen your internet browser and try again.  If you are still having issues with submitting application, please report issue to Minnies staff.');
     // document.getElementById('submitBtn').disabled = false;
      //alert(response.ErrorMessage);
      //console.log("Error: "+ response.ErrorMessage);
    }

})
      }

     
  
    componentDidMount() {

    document.getElementById('submitBtn').disabled = false;

     
    }
  
    componentWillUnmount() {
     
    }

    isValidated() {
      return true;

    }
  
    
    render() {
      return (
         <div>
           <div className="row">
        <div className="col-sm-2">
        <img src={logo} alt="Logo" />
        </div>
        <div className="col-sm-10">
         <h4>This is the summary page.  Please verify that the info is correct before submitting.</h4>
         </div>
         </div>
         <hr />
          <h2>First Name: {sessionStorage.getItem('fName')}</h2>
          <h2>Middle Name: {sessionStorage.getItem('mName')}</h2>
          <h2>Last Name: {sessionStorage.getItem('lName')}</h2>
          <h2>Birthday: {sessionStorage.getItem('birthday_month') + "/" + sessionStorage.getItem('birthday_day') + "/" + sessionStorage.getItem('birthday_year')}</h2>
          <h2>Gender: {sessionStorage.getItem('gender')}</h2>
          <h2>ReferredBy Name: {sessionStorage.getItem('referredby')}</h2>
          <h2>Ethnicity: {sessionStorage.getItem('ethnicity')}</h2>
          <h2>Email: {sessionStorage.getItem('email')}</h2>
          <h2>Phone: {sessionStorage.getItem('phone')}</h2>
          <h2>Street Address: {sessionStorage.getItem('address')}</h2>
          <h2>City: {sessionStorage.getItem('city')}</h2>
          <h2>State: {sessionStorage.getItem('state')}</h2>
          <h2>Zip: {sessionStorage.getItem('zip')}</h2>
          <h2>Status: {sessionStorage.getItem('military')}</h2>
          <h2>Employment Status: {sessionStorage.getItem('employee')}</h2>
          <h2>Income: {sessionStorage.getItem('income')}</h2>
          <h2>Food Stamps/SNAP?: {sessionStorage.getItem('snapyn')}</h2>
          <h2>Reduce Lunch?: {sessionStorage.getItem('reduceyn')}</h2>
          <h2>Learn about Food Stamps?: {sessionStorage.getItem('learnyn')}</h2>
          <h2>Household1 Name : {sessionStorage.getItem('household1name')}</h2>
          <h2>Household1 Birthday: {sessionStorage.getItem('household1birthday')}</h2>
          <h2>Household1 Gender: {sessionStorage.getItem('household1gender')}</h2>
          <h2>Household2 Name : {sessionStorage.getItem('household2name')}</h2>
          <h2>Household2 Birthday: {sessionStorage.getItem('household2birthday')}</h2>
          <h2>Household2 Gender: {sessionStorage.getItem('household2gender')}</h2>
          <h2>Household3 Name : {sessionStorage.getItem('household3name')}</h2>
          <h2>Household3 Birthday: {sessionStorage.getItem('household3birthday')}</h2>
          <h2>Household3 Gender: {sessionStorage.getItem('household3gender')}</h2>

          <br />
          <div>
          <h2>Proof of Residence Picture:</h2>
          <img src={`data:image/jpeg;base64,${window.atob(this.state.myPic)}`} />
          </div>
          <hr></hr>
          <p>By clicking submit, you certify that the information provided is true and you agree to provide additional information upon request.</p>

          <p>Your information is confidential.  Minnie's Food Pantry collects the information above to ensure effective communication, properly track client service and provide updates regarding upcoming events and special service dates. By providing your phone number you agree to opt in for text messages. Service fees may apply based on your carrier.</p>
          <p>Media Release: By receiving assistance from Minnie’s Food Pantry, you consent to your voice and likeness being used without compensation in films and tapes in any and all media, including print, publication and use on social media, whether known or hereafter devised, and you release Minnie’s Food Pantry and media affiliates and their successors, assigns and licenses from any liability on account of such usage.</p>
          <p>Minnie's Food Pantry does not discriminate against applicants on the basis of race, age, gender, disabilities, sexual orientation, socio-economic background or ethnicity.</p>
          <p>By completing this form and receiving service at Minnie's Food Pantry, you understand that any injury incurred while at Minnie's Food Pantry will not be covered by workers’ compensation</p>
          <button id="submitBtn" className="btn btn-prev btn-primary btn-lg" onClick={()=> this.submitGuest()}>Submit New Guest Application</button>
          <br></br>  <br></br>
       </div>
      )
    }
  }

  export default withRouter(SummarySubmit)