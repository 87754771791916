import React from 'react';
import StepZilla from "react-stepzilla";
import './App.css';
import GuestInfo from './components/GuestInfo';

import './css/main.css';
import TakePicture from './components/TakePicture';
import SummarySubmit from './components/SummarySubmit';
import ThankYou from './components/ThankYou';

import {  BrowserRouter as Router,Switch,Route } from 'react-router-dom'

function App() {

  const steps =
    [
        {name: 'Enter Guest Info', component: <GuestInfo />},
        {name: 'Submit Proof of Residence Pic', component: <TakePicture />},
        {name: 'Summary/Submit', component: <SummarySubmit />},
  
    ]

    function saveToSession(step) {
  
    }
 
  return (
    
    <div>
<div className='step-progress container'>
<StepZilla steps={steps} showSteps={true} stepsNavigation={true} 
          prevBtnOnLastStep={true} dontValidate={false} onStepChange={(step) => saveToSession(step)} />
    </div>
    
    </div>
  );
}

export default App;
