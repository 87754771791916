import React, { Component } from 'react'
import logo from '../images/minniesLogo.PNG';
class GuestInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  saveToSession() {

    //var fname = document.getElementById("fName");

    // sessionStorage.setItem('fName', document.getElementById("fName").value);
    // sessionStorage.setItem('mName', document.getElementById("mName").value);
    // sessionStorage.setItem('lName', document.getElementById("lName").value);
    // sessionStorage.setItem('birthday', document.getElementById("birthday").value);
    // sessionStorage.setItem('gender', document.getElementById("gender").value);
    // sessionStorage.setItem('referredby', document.getElementById("referredby").value);
    // sessionStorage.setItem('ethnicity', document.getElementById("ethnicity").value);
    // sessionStorage.setItem('email', document.getElementById("email").value);
    // sessionStorage.setItem('phone', document.getElementById("phone").value);
    // sessionStorage.setItem('address', document.getElementById("address").value);
    // sessionStorage.setItem('city', document.getElementById("city").value);
    // sessionStorage.setItem('state', document.getElementById("state").value);
    // sessionStorage.setItem('zip', document.getElementById("zip").value);
    // sessionStorage.setItem('military', document.getElementById("military").value);
    // sessionStorage.setItem('employee', document.getElementById("employee").value);
    // sessionStorage.setItem('income', document.getElementById("income").value);
    // sessionStorage.setItem('snapyn', document.getElementById("snapyn").value);
    // sessionStorage.setItem('reduceyn', document.getElementById("reduceyn").value);
    // sessionStorage.setItem('learnyn', document.getElementById("learnyn").value);
    // sessionStorage.setItem('household1name', document.getElementById("household1name").value);
    // sessionStorage.setItem('household1birthday', document.getElementById("household1birthday").value);
    // sessionStorage.setItem('household1gender', document.getElementById("household1gender").value);
    // sessionStorage.setItem('household2name', document.getElementById("household2name").value);
    // sessionStorage.setItem('household2birthday', document.getElementById("household2birthday").value);
    // sessionStorage.setItem('household2gender', document.getElementById("household2gender").value);
    // sessionStorage.setItem('household3name', document.getElementById("household3name").value);
    // sessionStorage.setItem('household3birthday', document.getElementById("household3birthday").value);
    // sessionStorage.setItem('household3gender', document.getElementById("household3gender").value);

  }

  componentDidMount() { 
    var urlParams = new URLSearchParams(window.location.search);
    var rName = urlParams.get('rname');
    
    if(rName != null && rName != "") {
       let referral = document.getElementById('referredby');
       referral.disabled = true;
       referral.value = rName.substr(0,20);      
    }

    if(sessionStorage.getItem('fName')){
      let field = document.getElementById('fName');
      field.value = sessionStorage.getItem('fName');
   }

   if(sessionStorage.getItem('mName')){
    let field = document.getElementById('mName');
    field.value = sessionStorage.getItem('mName');
    }

    if(sessionStorage.getItem('lName')){
      let field = document.getElementById('lName');
      field.value = sessionStorage.getItem('lName');
   }

   if(sessionStorage.getItem('birthday_month')){
    let field = document.getElementById('birthday_month');
    field.value = sessionStorage.getItem('birthday_month');
 }

 if(sessionStorage.getItem('birthday_day')){
  let field = document.getElementById('birthday_day');
  field.value = sessionStorage.getItem('birthday_day');
}

if(sessionStorage.getItem('birthday_year')){
  let field = document.getElementById('birthday_year');
  field.value = sessionStorage.getItem('birthday_year');
}

 if(sessionStorage.getItem('gender')){
  let field = document.getElementById('gender');
  field.value = sessionStorage.getItem('gender');
}

if(sessionStorage.getItem('referredby')){
  let field = document.getElementById('referredby');
  field.value = sessionStorage.getItem('referredby');
}

if(sessionStorage.getItem('ethnicity')){
  let field = document.getElementById('ethnicity');
  field.value = sessionStorage.getItem('ethnicity');
}

if(sessionStorage.getItem('email')){
  let field = document.getElementById('email');
  field.value = sessionStorage.getItem('email');
}

if(sessionStorage.getItem('phone')){
  let field = document.getElementById('phone');
  field.value = sessionStorage.getItem('phone');
}

if(sessionStorage.getItem('address')){
  let field = document.getElementById('address');
  field.value = sessionStorage.getItem('address');
}

if(sessionStorage.getItem('city')){
  let field = document.getElementById('city');
  field.value = sessionStorage.getItem('city');
}

if(sessionStorage.getItem('state')){
  let field = document.getElementById('state');
  field.value = sessionStorage.getItem('state');
}

if(sessionStorage.getItem('zip')){
  let field = document.getElementById('zip');
  field.value = sessionStorage.getItem('zip');
}

if(sessionStorage.getItem('military')){
  let field = document.getElementById('military');
  field.value = sessionStorage.getItem('military');
}

if(sessionStorage.getItem('employee')){
  let field = document.getElementById('employee');
  field.value = sessionStorage.getItem('employee');
}

if(sessionStorage.getItem('income')){
  let field = document.getElementById('income');
  field.value = sessionStorage.getItem('income');
}

if(sessionStorage.getItem('snapyn')){
  let field = document.getElementById('snapyn');
  field.value = sessionStorage.getItem('snapyn');
}

if(sessionStorage.getItem('reduceyn')){
  let field = document.getElementById('reduceyn');
  field.value = sessionStorage.getItem('reduceyn');
}

if(sessionStorage.getItem('learnyn')){
  let field = document.getElementById('learnyn');
  field.value = sessionStorage.getItem('learnyn');
}

if(sessionStorage.getItem('household1name')){
  let field = document.getElementById('household1name');
  field.value = sessionStorage.getItem('household1name');
}

if(sessionStorage.getItem('household1birthday')){
  let field = document.getElementById('household1birthday');
  field.value = sessionStorage.getItem('household1birthday');
}

if(sessionStorage.getItem('household1gender')){
  let field = document.getElementById('household1gender');
  field.value = sessionStorage.getItem('household1gender');
}

if(sessionStorage.getItem('household2name')){
  let field = document.getElementById('household2name');
  field.value = sessionStorage.getItem('household2name');
}

if(sessionStorage.getItem('household2birthday')){
  let field = document.getElementById('household2birthday');
  field.value = sessionStorage.getItem('household2birthday');
}

if(sessionStorage.getItem('household2gender')){
  let field = document.getElementById('household2gender');
  field.value = sessionStorage.getItem('household2gender');
}

if(sessionStorage.getItem('household3name')){
  let field = document.getElementById('household3name');
  field.value = sessionStorage.getItem('household3name');
}

if(sessionStorage.getItem('household3birthday')){
  let field = document.getElementById('household3birthday');
  field.value = sessionStorage.getItem('household3birthday');
}

if(sessionStorage.getItem('household3gender')){
  let field = document.getElementById('household3gender');
  field.value = sessionStorage.getItem('household3gender');
}

  }

  isValidated() {

    let formValidated = true;

    if(sessionStorage.getItem('fName')){
      let field = document.getElementById('fName');
      if(field.value == "") {
      formValidated = false;
      field.style.borderColor = "red";
      }
      else {
        field.style.borderColor = "black";
      }
   }
   else{
    formValidated = false;
    let field = document.getElementById('fName');
    field.style.borderColor = "red";
   }

   if(sessionStorage.getItem('lName')){
     let field = document.getElementById('lName');
     if(field.value == "") {
      formValidated = false;
      field.style.borderColor = "red";
      }
      else {
        field.style.borderColor = "black";
      }
   }
   else{
    let field = document.getElementById('lName');
    formValidated = false;
    field.style.borderColor = "red";
   }

  if(sessionStorage.getItem('birthday_month')){
   let field = document.getElementById('birthday_month');
   if(field.value == "") {
    formValidated = false;
    field.style.borderColor = "red";
    }
    else {
      field.style.borderColor = "black";
    }
 }
 else{
  formValidated = false;
  let field = document.getElementById('birthday_month');
  field.style.borderColor = "red";
 }


 if(sessionStorage.getItem('birthday_day')){
  let field = document.getElementById('birthday_day');
  if(field.value == "") {
   formValidated = false;
   field.style.borderColor = "red";
   }
   else {
     field.style.borderColor = "black";
   }
}
else{
 formValidated = false;
 let field = document.getElementById('birthday_day');
 field.style.borderColor = "red";
}

if(sessionStorage.getItem('birthday_year')){
  let field = document.getElementById('birthday_year');
  if(field.value == "") {
   formValidated = false;
   field.style.borderColor = "red";
   }
   else {
     field.style.borderColor = "black";
   }
}
else{
 formValidated = false;
 let field = document.getElementById('birthday_year');
 field.style.borderColor = "red";
}
   
if(sessionStorage.getItem('gender')){
 let field = document.getElementById('gender');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('gender');
field.style.borderColor = "red";
}
 
if(sessionStorage.getItem('ethnicity')){
  let field = document.getElementById('ethnicity');
  if(field.value == "") {
   formValidated = false;
   field.style.borderColor = "red";
   }
   else {
     field.style.borderColor = "black";
   }
 }
 else{
 formValidated = false;
 let field = document.getElementById('ethnicity');
 field.style.borderColor = "red";
 }

 
if(sessionStorage.getItem('phone')){
 let field = document.getElementById('phone');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('phone');
field.style.borderColor = "red";
}
 
if(sessionStorage.getItem('address')){
 let field = document.getElementById('address');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('address');
field.style.borderColor = "red";
}

 
if(sessionStorage.getItem('city')){
 let field = document.getElementById('city');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('city');
field.style.borderColor = "red";
}
 

 
if(sessionStorage.getItem('zip')){
 let field = document.getElementById('zip');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('zip');
field.style.borderColor = "red";
}


if(sessionStorage.getItem('military')){
 let field = document.getElementById('military');
 if(field.value == "") {
  formValidated = false;
  field.style.borderColor = "red";
  }
  else {
    field.style.borderColor = "black";
  }
}
else{
formValidated = false;
let field = document.getElementById('military');
field.style.borderColor = "red";
}

if(sessionStorage.getItem('employee')){
  let field = document.getElementById('employee');
  if(field.value == "") {
   formValidated = false;
   field.style.borderColor = "red";
   }
   else {
     field.style.borderColor = "black";
   }
 }
 else{
 formValidated = false;
 let field = document.getElementById('employee');
 field.style.borderColor = "red";
 }
  

 if(sessionStorage.getItem('income')){
  let field = document.getElementById('income');
  if(field.value == "") {
   formValidated = false;
   field.style.borderColor = "red";
   }
   else {
     field.style.borderColor = "black";
   }
 }
 else{
 formValidated = false;
 let field = document.getElementById('income');
 field.style.borderColor = "red";
 }


 if(sessionStorage.getItem('snapyn')){
  let field = document.getElementById('snapyn');
  if(field.value == "") {
   formValidated = false;
   field.style.borderColor = "red";
   }
   else {
     field.style.borderColor = "black";
   }
 }
 else{
 formValidated = false;
 let field = document.getElementById('snapyn');
 field.style.borderColor = "red";
 }

 if(sessionStorage.getItem('reduceyn')){
  let field = document.getElementById('reduceyn');
  if(field.value == "") {
   formValidated = false;
   field.style.borderColor = "red";
   }
   else {
     field.style.borderColor = "black";
   }
 }
 else{
 formValidated = false;
 let field = document.getElementById('reduceyn');
 field.style.borderColor = "red";
 }
  
 if(sessionStorage.getItem('learnyn')){
  let field = document.getElementById('learnyn');
  if(field.value == "") {
   formValidated = false;
   field.style.borderColor = "red";
   }
   else {
     field.style.borderColor = "black";
   }
 }
 else{
 formValidated = false;
 let field = document.getElementById('learnyn');
 field.style.borderColor = "red";
 }
 

  if(formValidated == false) 
  alert("Please complete all required fields that are highlighted in red.");

    return formValidated;

   }

  componentWillUnmount() {
    this.saveToSession();
    sessionStorage.setItem('state', document.getElementById('state').value);

  }

  saveOnChangeToSession(elementName) {
    sessionStorage.setItem(elementName, document.getElementById(elementName).value);
  }


  render() {

    const divStyle = {
    margin : '0px',
    padding : '3px',
    fontSize : '14px'
    };

    const addPadding = {
      
      paddingBottom : '15px',
      
      };
    const addBordering = {
      border : '1px solid',
      width : 'inherit'
      };

      const zipWidth = {
        width: '100px',
        border : '1px solid'
        };

    return (
      <div >
        <div className="row">
        <div className="col-sm-2">
        <img src={logo} alt="Logo" />
        </div>
        <div className="col-sm-6">
        <h3 style={{textAlign: "center"}}>RECIPIENT FORM</h3>
        <h4 style={{textAlign: "center"}}>Happy to Serve You!</h4>
        </div>
        </div>
        <div className="row">
          <div style={divStyle} className="col-sm-2">
            FIRST NAME:
            </div>
          <div style={divStyle} className="col-sm-2">
             <input style={addBordering} onChange={() => this.saveOnChangeToSession('fName')}  type="text" id="fName" />
          </div>

          <div style={divStyle} className="col-sm-2">
            MIDDLE NAME:
            </div>
          <div style={divStyle} className="col-sm-2">
             <input style={addBordering} onChange={() => this.saveOnChangeToSession('mName')} type="text" id="mName" />
          </div>

          <div style={divStyle} className="col-sm-2">
            LAST NAME:
            </div>
          <div style={divStyle} className="col-sm-2">
             <input style={addBordering} onChange={() => this.saveOnChangeToSession('lName')} type="text" id="lName" />
          </div>
        </div>

<br></br>
        <div className="row">
          <div style={divStyle} className="col-sm-2">
            BIRTHDAY:
            </div>
          <div style={divStyle} className="col-sm-2">
             {/* <input style={addBordering} onChange={() => this.saveOnChangeToSession('birthday')} type="date" id="birthday" /> */}
          
    <select style={addBordering} type="date" onChange={() => this.saveOnChangeToSession('birthday_month')} id="birthday_month" name="birthday_month">
    <option value="">Month</option>
    <option value="01">January</option>
    <option value="02">February</option>
    <option value="03">March</option>
    <option value="04">April</option>
    <option value="05">May</option>
    <option value="06">June</option>
    <option value="07">July</option>
    <option value="08">August</option>
    <option value="09">September</option>
    <option value="10">October</option>
    <option value="11">November</option>
    <option value="12">December</option>
</select>

<select style={addBordering} onChange={() => this.saveOnChangeToSession('birthday_day')}  id="birthday_day" name="birthday_day">
    <option value="">Day</option>
    <option value="01">01</option>
    <option value="02">02</option>
    <option value="03">03</option>
    <option value="04">04</option>
    <option value="05">05</option>
    <option value="06">06</option>
    <option value="07">07</option>
    <option value="08">08</option>
    <option value="09">09</option>
    <option value="10">10</option>
    <option value="11">11</option>
    <option value="12">12</option>
    <option value="13">13</option>
    <option value="14">14</option>
    <option value="15">15</option>
    <option value="16">16</option>
    <option value="17">17</option>
    <option value="18">18</option>
    <option value="19">19</option>
    <option value="20">20</option>
    <option value="21">21</option>
    <option value="22">22</option>
    <option value="23">23</option>
    <option value="24">24</option>
    <option value="25">25</option>
    <option value="26">26</option>
    <option value="27">27</option>
    <option value="28">28</option>
    <option value="29">29</option>
    <option value="30">30</option>
    <option value="31">31</option>
</select>
    <select style={addBordering} onChange={() => this.saveOnChangeToSession('birthday_year')} id="birthday_year" name="birthday_year">
    <option value="">Year</option>
    <option value="1930">1930</option>
    <option value="1931">1931</option>
    <option value="1932">1932</option>
    <option value="1933">1933</option>
    <option value="1934">1934</option>
    <option value="1935">1935</option>
    <option value="1936">1936</option>
    <option value="1937">1937</option>
    <option value="1938">1938</option>
    <option value="1939">1939</option>
    <option value="1940">1940</option>
    <option value="1941">1941</option>
    <option value="1942">1942</option>
    <option value="1943">1943</option>
    <option value="1944">1944</option>
    <option value="1945">1945</option>
    <option value="1946">1946</option>
    <option value="1947">1947</option>
    <option value="1948">1948</option>
    <option value="1949">1949</option>
    <option value="1950">1950</option>
    <option value="1951">1951</option>
    <option value="1952">1952</option>
    <option value="1953">1953</option>
    <option value="1954">1954</option>
    <option value="1955">1955</option>
    <option value="1956">1956</option>
    <option value="1957">1957</option>
    <option value="1958">1958</option>
    <option value="1959">1959</option>
    <option value="1960">1960</option>
    <option value="1961">1961</option>
    <option value="1962">1962</option>
    <option value="1963">1963</option>
    <option value="1964">1964</option>
    <option value="1965">1965</option>
    <option value="1966">1966</option>
    <option value="1967">1967</option>
    <option value="1968">1968</option>
    <option value="1969">1969</option>
    <option value="1970">1970</option>
    <option value="1971">1971</option>
    <option value="1972">1972</option>
    <option value="1973">1973</option>
    <option value="1974">1974</option>
    <option value="1975">1975</option>
    <option value="1976">1976</option>
    <option value="1977">1977</option>
    <option value="1978">1978</option>
    <option value="1979">1979</option>
    <option value="1980">1980</option>
    <option value="1981">1981</option>
    <option value="1982">1982</option>
    <option value="1983">1983</option>
    <option value="1984">1984</option>
    <option value="1985">1985</option>
    <option value="1986">1986</option>
    <option value="1987">1987</option>
    <option value="1988">1988</option>
    <option value="1989">1989</option>
    <option value="1990">1990</option>
    <option value="1991">1991</option>
    <option value="1992">1992</option>
    <option value="1993">1993</option>
    <option value="1994">1994</option>
    <option value="1995">1995</option>
    <option value="1996">1996</option>
    <option value="1997">1997</option>
    <option value="1998">1998</option>
    <option value="1999">1999</option>
    <option value="2000">2000</option>
    <option value="2001">2001</option>
    <option value="2002">2002</option>
    <option value="2003">2003</option>
    <option value="2004">2004</option>
    <option value="2005">2005</option>
    <option value="2006">2006</option>
    <option value="2007">2007</option>
    <option value="2008">2008</option>
    <option value="2009">2009</option>
    <option value="2010">2010</option>
    <option value="2011">2011</option>
    <option value="2012">2012</option>
    <option value="2013">2013</option>
    <option value="2014">2014</option>
    <option value="2015">2015</option>
    <option value="2016">2016</option>
    <option value="2017">2017</option>
    <option value="2018">2018</option>
    <option value="2019">2019</option>
    <option value="2020">2020</option>
    <option value="2021">2021</option>
</select>    
</div>
        
          <div style={divStyle} className="col-sm-2">
            PLEASE SELECT GENDER:
            </div>
          <div style={divStyle} className="col-sm-2">
             <select style={addBordering} onChange={() => this.saveOnChangeToSession('gender')} id="gender">
            <option value=""></option>
              <option value="M">Male</option>
              <option value="F">Female</option>
            </select>
          </div>

          <div style={divStyle} className="col-sm-2">
            REFERRED BY:
            </div>
          <div style={divStyle} className="col-sm-2">
             <input style={addBordering} onChange={() => this.saveOnChangeToSession('referredby')} type="text" id="referredby" />
          </div>
        </div>

<br></br>
        <div className="row">
          <div style={divStyle} className="col-sm-2">
            ETHNICITY
            </div>
          <div style={divStyle} className="col-sm-2">
           <select style={addBordering} onChange={() => this.saveOnChangeToSession('ethnicity')} id="ethnicity">
            <option value=""></option>
              <option value="White">White</option>
              <option value="Black">Black</option>
              <option value="hispanic">Hispanic</option>
              <option value="NativeAmerican">Native American</option>
              <option value="Asian">Asian</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div style={divStyle} className="col-sm-2">
            EMAIL:
            </div>
          <div style={divStyle} className="col-sm-2">
             <input style={addBordering} onChange={() => this.saveOnChangeToSession('email')} type="text" id="email" />
          </div>

          <div style={divStyle} className="col-sm-2">
            CELL PHONE:
            </div>
          <div style={divStyle} className="col-sm-2">
             <input style={addBordering} onChange={() => this.saveOnChangeToSession('phone')} type="text" id="phone" />
          </div>
        </div>

<br></br>
        <div  className="row">
          <div style={divStyle} className="col-sm-2">
            ADDRESS:
            </div>
          <div style={divStyle} className="col-sm-2">
             <input style={addBordering} onChange={() => this.saveOnChangeToSession('address')} type="text" id="address" />
          </div>

          <div style={divStyle} className="col-sm-2">
            CITY:
            </div>
          <div style={divStyle} className="col-sm-2">
             <input style={addBordering} onChange={() => this.saveOnChangeToSession('city')} type="text" id="city" />
          </div>

          <div style={divStyle} className="col-sm-2">
            {/* STATE: <input onChange={() => this.saveOnChangeToSession('state')} type="text" id="state" value="TX" /> */}
          STATE:
          </div>
          <div style={divStyle} className="col-sm-2">
             <select defaultValue={'TX'} style={addBordering} onChange={() => this.saveOnChangeToSession('state')} id="state" >
	<option value="AL">Alabama</option>
	<option value="AK">Alaska</option>
	<option value="AZ">Arizona</option>
	<option value="AR">Arkansas</option>
	<option value="CA">California</option>
	<option value="CO">Colorado</option>
	<option value="CT">Connecticut</option>
	<option value="DE">Delaware</option>
	<option value="DC">District Of Columbia</option>
	<option value="FL">Florida</option>
	<option value="GA">Georgia</option>
	<option value="HI">Hawaii</option>
	<option value="ID">Idaho</option>
	<option value="IL">Illinois</option>
	<option value="IN">Indiana</option>
	<option value="IA">Iowa</option>
	<option value="KS">Kansas</option>
	<option value="KY">Kentucky</option>
	<option value="LA">Louisiana</option>
	<option value="ME">Maine</option>
	<option value="MD">Maryland</option>
	<option value="MA">Massachusetts</option>
	<option value="MI">Michigan</option>
	<option value="MN">Minnesota</option>
	<option value="MS">Mississippi</option>
	<option value="MO">Missouri</option>
	<option value="MT">Montana</option>
	<option value="NE">Nebraska</option>
	<option value="NV">Nevada</option>
	<option value="NH">New Hampshire</option>
	<option value="NJ">New Jersey</option>
	<option value="NM">New Mexico</option>
	<option value="NY">New York</option>
	<option value="NC">North Carolina</option>
	<option value="ND">North Dakota</option>
	<option value="OH">Ohio</option>
	<option value="OK">Oklahoma</option>
	<option value="OR">Oregon</option>
	<option value="PA">Pennsylvania</option>
	<option value="RI">Rhode Island</option>
	<option value="SC">South Carolina</option>
	<option value="SD">South Dakota</option>
	<option value="TN">Tennessee</option>
	<option value="TX">Texas</option>
	<option value="UT">Utah</option>
	<option value="VT">Vermont</option>
	<option value="VA">Virginia</option>
	<option value="WA">Washington</option>
	<option value="WV">West Virginia</option>
	<option value="WI">Wisconsin</option>
	<option value="WY">Wyoming</option>
</select>				
          </div>

         
        </div>
<br></br>
<div className="row">
          <div style={divStyle} className="col-sm-2">
            ZIP:  
            </div>
          <div style={divStyle} className="col-sm-3">
             <input style={zipWidth} onChange={() => this.saveOnChangeToSession('zip')} type="number" id="zip" />
          </div>
</div>
<br></br>
        <div className="row">
          <div style={divStyle} className="col-sm-2">
            MILITARY STATUS:  
            </div>
          <div style={divStyle} className="col-sm-2">
             <select style={addBordering} onChange={() => this.saveOnChangeToSession('military')} id="military">
              <option value=""></option>
              <option value="Disabled">Disabled</option>
              <option value="Active">Active</option>
              <option value="Veteran">Veteran</option>
              <option value="None">None</option>
            </select>
          </div>

          <div style={divStyle} className="col-sm-2">
            EMPLOYMENT STATUS:
              </div>
          <div style={divStyle} className="col-sm-2">
              <select style={addBordering} onChange={() => this.saveOnChangeToSession('employee')} id="employee">
            <option value=""></option>
              <option value="Y">Full Time</option>
              <option value="Y">Part Time</option>
              <option value="N">UnEmployed</option>
            </select>
          </div>
          <div style={divStyle} className="col-sm-2">
            MONTHLY INCOME:
            </div>
          <div style={divStyle} className="col-sm-2">
             <input style={addBordering} onChange={() => this.saveOnChangeToSession('income')} type="number" id="income" />
          </div>

        </div>
        <br />
        <div className="row">
          <div style={divStyle} className="col-sm-8">
            Currently Receive SNAP/Food Stamps?
            </div>
          <div style={divStyle} className="col-sm-4">
             <select style={addBordering} onChange={() => this.saveOnChangeToSession('snapyn')} id="snapyn">
            <option value=""></option>
              <option value="Y">YES</option>
              <option value="N">NO</option>
            </select>
          </div>

        </div>

        <div className="row">

          <div style={divStyle} className="col-sm-8">
            Children on Free/Reduced Meals through school sponsored programs?
            </div>
          <div style={divStyle} className="col-sm-4">
             <select style={addBordering} onChange={() => this.saveOnChangeToSession('reduceyn')} id="reduceyn">
            <option value=""></option>
              <option value="Y">YES</option>
              <option value="N">NO</option>
            </select>
          </div>

        </div>

        <div className="row">

          <div style={divStyle} className="col-sm-8">
            Want to Learn about SNAP?
            </div>
          <div style={divStyle} className="col-sm-4">
             <select style={addBordering} onChange={() => this.saveOnChangeToSession('learnyn')} id="learnyn">
            <option value=""></option>
              <option value="Y">YES</option>
              <option value="N">NO</option>
            </select>
          </div>

        </div>
        <br />
        <h4>HOUSEHOLD INFORMATION: Please list the following information for EACH member of your household.</h4>

        {/* <div className="row">
          <div className="col-sm-4">Name</div>
          <div className="col-sm-4">Date of Birth</div>
          <div className="col-sm-4">Male/Female</div>
        </div> */}
        <br></br>
        <div className="row">
          <div style={divStyle} className="col-sm-2">
          HouseHold Name 1:
          </div>
          <div style={divStyle} className="col-sm-2">
             <input style={addBordering} type="text" onChange={() => this.saveOnChangeToSession('household1name')} id="household1name" />
          </div>

          <div style={divStyle} className="col-sm-2">
          HouseHold Birthday 1:
          </div>
          <div style={divStyle} className="col-sm-2">
             <input style={addBordering} type="date" onChange={() => this.saveOnChangeToSession('household1birthday')} id="household1birthday" />
          </div>

          <div style={divStyle} className="col-sm-2">
          HouseHold Gender 1:  
          </div>
          <div style={divStyle} className="col-sm-2">
             <select style={addBordering} onChange={() => this.saveOnChangeToSession('household1gender')} id="household1gender">
            <option value=""></option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
        </div>

        <div className="row">

          <div style={divStyle} className="col-sm-2">
          HouseHold Name 2:
          </div>
          <div style={divStyle} className="col-sm-2">
             <input style={addBordering} type="text" onChange={() => this.saveOnChangeToSession('household2name')} id="household2name" />
          </div>

          <div style={divStyle} className="col-sm-2">
          HouseHold Birthday 2:
          </div>
          <div style={divStyle} className="col-sm-2">
             <input style={addBordering} type="date" onChange={() => this.saveOnChangeToSession('household2birthday')} id="household2birthday" />
          </div>

          <div style={divStyle} className="col-sm-2">
        HouseHold Gender 2: 
         </div>
          <div style={divStyle} className="col-sm-2">
             <select style={addBordering} onChange={() => this.saveOnChangeToSession('household2gender')} id="household2gender">
            <option value=""></option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
        </div>

        <div className="row">
          <div style={divStyle} className="col-sm-2">
          HouseHold Name 3:  
          </div>
          <div style={divStyle} className="col-sm-2">
             <input style={addBordering} type="text" onChange={() => this.saveOnChangeToSession('household3name')} id="household3name" />
          </div>

          <div style={divStyle} className="col-sm-2">
          HouseHold Birthday 3:
          </div>
          <div style={divStyle} className="col-sm-2">
           <input style={addBordering} type="date" onChange={() => this.saveOnChangeToSession('household3birthday')} id="household3birthday" />
          </div>

          <div style={divStyle} className="col-sm-2">
        Household Gender 3:
         </div>
          <div style={divStyle} className="col-sm-2">
             <select style={addBordering} onChange={() => this.saveOnChangeToSession('household3gender')} id="household3gender">
            <option value=""></option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </div>
          </div>
          <br /><br />
        </div>

    )
  }
}


export default GuestInfo